import React from "react"
import './../custom.scss';
import Header from "./header"
import Footer from "./footer"
import Container from "react-bootstrap/Container"



const Layout = (props) => {

  return (
    <>
      <Header page={props.page}/>
      <Container fluid={props.fluid} style={{paddingTop: "75px",paddingBottom: "100px"}}>
        <main>{props.children}</main>
      </Container>
      <Footer/>
    </>
  )
}

export default Layout
