import { Link } from "gatsby"
import React from "react"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import { Container, Row, Col } from 'reactstrap';
import Image from 'react-bootstrap/Image'
import Logo from '../images/Logo.png'
import { charte } from "./charte"

const Header = (props) => <Container fluid style={{ paddingTop: "4rem" }}>
  <header>
    <Row>
      <Col>
        <Image src={Logo} fluid style={{ margin: "2rem" }}/>
      </Col>
      <Col className={"my-auto"}>
        <div style={{ textAlign: "center", color: charte.primary, fontSize: "xx-large" }}>Make <text style={{color: charte.success}}>Re</text>search  More <text style={{color: charte.success}}>Re</text>sponsible!
        </div>
        <div style={{ textAlign: "center", color: charte.dark, fontSize: "xx-medium" }}>We are committed to facing
          the climate emergency
        </div>
      </Col>
    </Row>


    <Navbar bg="white" className="justify-content-center" fixed='top'>

      <Nav variant="tabs" className="justify-content-center" activeKey={props.page}>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Nav.Item>
          <Nav.Link eventKey="Home"><Link to="/" style={{color: charte.dark}}>Home</Link></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="MonthlyTips"><Link to="/MonthlyTips" style={{color: charte.dark}}>Monthly Tips</Link></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="GCchallenge"><Link to="/GCchallenge" style={{color: charte.dark}}>GreenCom Challenge</Link></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Meetings"><Link to="/Meetings" style={{color: charte.dark}}>Meetings</Link></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Resources"><Link to="/Resources" style={{color: charte.dark}}>Resources</Link></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="About"><Link to="/About" style={{color: charte.dark}}>About</Link></Nav.Link>
        </Nav.Item>
      </Nav>
    </Navbar>
  </header>
</Container>

export default Header
