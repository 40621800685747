import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import amImage from "../images/amazonie.jpg"
import { charte } from "./charte"


import App from "./cal"


const Footer = () => <Container fluid style={{backgroundColor: charte.secondary, paddingTop: "4rem", paddingBottom: "4rem" }}>

    <Row style={{flexDirection: Row, justifyContent: "space-around"}}>
    <Col style={{ color: "white" }} xs={3}>
      <App/>
    </Col>

    <Col style={{ color: "white" }} xs={2}>
      <p/>
      <h2>Adress</h2>
      <p>Collège de France CIRB</p>
      <p>11 place Marcelin Berthelot</p>
      <p>75005 Paris</p>
    </Col>

    <style type="text/css">
      {`.btn-flat {
      background-color: rgb(25,21,129);
      color: white;
    } `} </style>

    <Col xs={3} style={{ color: "white" }}>
      <p/>
      <h2 style={{}}>Contact Us</h2>
      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email"/>
        </Form.Group>
        <Button variant="flat" type="submit">
          Submit
        </Button>
      </Form>
    </Col>

  </Row>
</Container>


export default Footer